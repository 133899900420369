import {action, computed, makeObservable, observable} from 'mobx';
import {UserDto} from '../models';
import {UserRepository} from '../repositories/user-repository';
import {AuthenticationStore} from './authentication-store';

export type UserStoreDependencies = {authenticationStore: AuthenticationStore; userRepository: UserRepository};

export class UserStore {
  @observable
  user: UserDto | null = null;

  private readonly authenticationStore: AuthenticationStore;

  private readonly userRepository: UserRepository;

  constructor(user: UserDto | null, deps: UserStoreDependencies) {
    this.authenticationStore = deps.authenticationStore;
    this.userRepository = deps.userRepository;
    this.setUser(user);
    makeObservable(this);
  }

  @computed
  get loggedIn() {
    return Boolean(this.user && this.authenticationStore.authorization);
  }

  @action
  setUser(loggedUser: UserDto | null) {
    this.user = loggedUser;
  }

  async login(username: string, password: string): Promise<void> {
    const result = await this.userRepository.authorize(username, password);
    this.authenticationStore.setAuthorization(result);
    const user = await this.userRepository.getMe();
    this.setUser(user);
  }

  logout() {
    this.setUser(null);
    this.authenticationStore.clear();
  }
}
