import {action, makeObservable, observable} from 'mobx';
import {AuthorizationDto, AuthorizationGetter} from '../models';

export class AuthenticationStore extends AuthorizationGetter {
  @observable
  authorization: AuthorizationDto | null = null;

  constructor(authorization: AuthorizationDto | null = null) {
    super();
    this.setAuthorization(authorization);
    makeObservable(this);
  }

  @action
  setAuthorization(
    dto: {
      accessToken: string;
      // Handle the case whereby the expirationDate is provided as a string instead of Date
      expirationDate: Date | string | null;
      userId: string;
    } | null,
  ) {
    if (dto) {
      const {expirationDate, ...rest} = dto;

      this.authorization = {
        ...rest,
        // Handle the case whereby the expirationDate is provided as a string instead of Date
        expirationDate: !expirationDate ? null : new Date(expirationDate),
      };
    } else {
      this.authorization = dto;
    }
  }

  getAuthorization() {
    return this.authorization || {accessToken: '', expirationDate: null, userId: ''};
  }

  @action
  clear() {
    this.authorization = null;
  }
}
