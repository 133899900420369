import {AuthorizationGetter} from '../models';

export interface LabApiConfig {
  hosts: {
    lab?: string;
  };
}

export interface RepositoryConfigParams<Api> {
  apiConfig: LabApiConfig;
  authorization: AuthorizationGetter;
  api?: Api;
}

export abstract class Repository<Api> {
  constructor(protected authorization: AuthorizationGetter, protected api: Api) {
    // empty-constructor
  }
}
