import {UserDto, AuthorizationDto} from '../models';

// TODO: Remove when backend is implemented
export class MockLabUserApi {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login(email: string, password: string): Promise<AuthorizationDto> {
    return Promise.resolve({
      accessToken: '123456789',
      expirationDate: new Date(),
      userId: 'a-user-id',
    });
  }

  getMe(): Promise<UserDto> {
    return Promise.resolve({
      id: 'id',
      displayName: 'Fake User',
      email: 'fake-login@xpertsea.com',
      phone: '+1 234 567 8901',
    });
  }
}
