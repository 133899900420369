import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {page} from './segment';

export function useRouteListener() {
  const location = useLocation();

  useEffect(() => {
    page();
  }, [location]);
}
