import React from 'react';

const SectionOne = () => {
  return (
    <div>
      <span>This is the content of Section 1</span>
    </div>
  );
};

export default SectionOne;
