import React from 'react';
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: 20,
  },
}));

// eslint-disable-next-line @typescript-eslint/ban-types
export default function PageContainer({children}: React.PropsWithChildren<{}>) {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {children}
    </main>
  );
}
