import {useEffect} from 'react';
import {reaction} from 'mobx';
import {UserDto, AuthorizationDto} from '../models';
import {UserStore} from './user-store';
import {AuthenticationStore} from './authentication-store';

export const SERIALIZED_AUTH_KEY = 'auth';
export const SERIALIZED_USER_KEY = 'user';

export function getPersistedUser(): UserDto | null {
  const serialized = localStorage.getItem(SERIALIZED_USER_KEY);
  return serialized ? JSON.parse(serialized) : null;
}

export function getPersistedAuth(): AuthorizationDto | null {
  const serialized = localStorage.getItem(SERIALIZED_AUTH_KEY);

  if (serialized) {
    try {
      const {expirationDate, ...rest} = JSON.parse(serialized);

      return {
        expirationDate: expirationDate ? new Date(expirationDate) : null,
        ...rest,
      };
    } catch (e) {
      return null;
    }
  }

  return null;
}

export function usePersistAuthentication(authStore: AuthenticationStore) {
  useEffect(() => {
    return reaction(
      () => authStore.authorization,
      (auth) => {
        if (auth) {
          localStorage.setItem(SERIALIZED_AUTH_KEY, JSON.stringify(auth));
        } else {
          localStorage.removeItem(SERIALIZED_AUTH_KEY);
        }
      },
    );
  }, [authStore.authorization]);
}

export function usePersistUser(userStore: UserStore) {
  useEffect(() => {
    return reaction(
      () => userStore.user,
      (user) => {
        if (user) {
          localStorage.setItem(SERIALIZED_USER_KEY, JSON.stringify(user));
        } else {
          localStorage.removeItem(SERIALIZED_USER_KEY);
        }
      },
    );
  }, [userStore.user]);
}
