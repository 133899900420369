import React, {useMemo} from 'react';
import {makeStyles, ThemeProvider} from '@material-ui/core';
import {BrowserRouter as Router} from 'react-router-dom';
import theme from './theme';
import Root from './pages/Root';
import {EnvironmentBadge} from './components';
import {initRepositories, RepositoriesProvider} from './repositories';
import {initAuthenticationStore, initStores, StoresProvider} from './stores';
import {usePersistAuthentication, usePersistUser} from './stores/persistence';

const useStyles = makeStyles((injectedTheme) => ({
  container: {
    backgroundColor: injectedTheme.palette.grey['100'],
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'inherit',
    },
  },
}));

const App = () => {
  const authenticationStore = useMemo(() => initAuthenticationStore(), []);
  const repositories = useMemo(() => initRepositories(authenticationStore), [authenticationStore]);
  const stores = useMemo(() => initStores(authenticationStore, repositories), [authenticationStore, repositories]);
  const {container} = useStyles();

  usePersistAuthentication(authenticationStore);
  usePersistUser(stores.userStore);

  return (
    <div className={container}>
      <StoresProvider value={stores}>
        <RepositoriesProvider value={repositories}>
          <Router>
            <ThemeProvider theme={theme}>
              <Root />
            </ThemeProvider>
          </Router>
          <EnvironmentBadge />
        </RepositoriesProvider>
      </StoresProvider>
    </div>
  );
};

export default App;
