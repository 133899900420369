import React, {useState} from 'react';
import {Paper, Container, TextField, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {observer} from 'mobx-react-lite';
import logo from '../../assets/lab_logo.png';
import {useStores} from '../../stores';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    paddingTop: '10vh',
  },
  loginBox: {
    maxWidth: 360,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      padding: theme.spacing(1),
    },
  },
  form: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(6),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  logo: {
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
  },
}));

const Login = observer(() => {
  const classes = useStyles();
  const {userStore} = useStores();

  const [username, setUsername] = useState('fake-login@xpertsea.com');
  const [password, setPassword] = useState('abcd1234');
  const [loading, setLoading] = useState(false);

  return (
    <Container className={classes.container}>
      <Paper className={classes.loginBox} elevation={3}>
        <img src={logo} className={classes.logo} alt="XpertSea" />
        <div className={classes.textContainer}>
          <h3>Welcome to the lab!</h3>
          <span>Sign into your account</span>
        </div>

        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setLoading(true);
            userStore
              .login(username, password)
              .then(() => {
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          }}>
          <TextField
            disabled={loading}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username"
            className={classes.field}
            id="email"
            label="Email"
            fullWidth
          />
          <TextField
            disabled={loading}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            className={classes.field}
            id="password"
            label="Password"
            fullWidth
            type="password"
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={loading}>
            Sign in
          </Button>
        </form>
      </Paper>
    </Container>
  );
});

export default Login;
