import {makeStyles} from '@material-ui/core';
import React from 'react';

const ENV = process.env.REACT_APP_ENV || 'development';

export const useStyles = makeStyles({
  environmentBadge: {
    display: 'inline-block',
    borderRadius: 3,
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontVariant: 'small-caps',
    padding: '3px 8px',
    position: 'absolute',
    bottom: 5,
    right: 5,
    userSelect: 'none',
    '&:hover': {
      opacity: 0.2,
    },
  },
});

export default function EnvironmentBadge() {
  const {environmentBadge} = useStyles();
  const colors = {
    production: '#e53935',
    staging: '#2196f3',
    development: '#2196f3',
  };

  return (
    <div
      data-testid="env-badge"
      className={environmentBadge}
      style={{backgroundColor: colors[ENV as keyof typeof colors]}}>
      {ENV}
    </div>
  );
}
