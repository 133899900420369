import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import React, {forwardRef} from 'react';
import {Link} from 'react-router-dom';

export default function ListItemLink({primary, to, icon}: {primary: string; icon?: React.ReactNode; to: string}) {
  const CustomLink = React.useMemo(
    () => forwardRef<HTMLAnchorElement, unknown>((linkProps, ref) => <Link ref={ref} to={to} {...linkProps} />),
    [to],
  );

  return (
    <ListItem button component={CustomLink}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </ListItem>
  );
}
