import React from 'react';
import {AppBar, Toolbar, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.common.black,
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  };
});

type Props = React.PropsWithChildren<{
  drawerOpen?: boolean;
}>;

export default function PageHeader({children, drawerOpen}: Props) {
  const classes = useStyles();

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </AppBar>
  );
}
