import {Repository, RepositoryConfigParams} from './repository';
import {UserDto, AuthorizationDto} from '../models';
import {MockLabUserApi} from '../apiMock/MockLabUserApi';

// TODO: use real api
export class UserRepository extends Repository<MockLabUserApi> {
  constructor(config: RepositoryConfigParams<MockLabUserApi>) {
    super(config.authorization, config.api || new MockLabUserApi());
  }

  authorize(email: string, password: string): Promise<AuthorizationDto> {
    return this.api.login(email, password);
  }

  async getMe(): Promise<UserDto> {
    return this.api.getMe();
  }
}
