import React from 'react';
import AssignmentIcon from '@material-ui/icons/AssignmentInd';
import {List} from '@material-ui/core';
import {ListItemLink} from '../../components';

const SideMenu = () => (
  <List>
    <ListItemLink primary="Section 1" icon={<AssignmentIcon />} to="/section-1" />
    <ListItemLink primary="Section 2" icon={<AssignmentIcon />} to="/section-2" />
  </List>
);

export default SideMenu;
