import {createContext, useContext} from 'react';
import {AuthenticationStore} from './authentication-store';
import {Repositories} from '../repositories';
import {UserStore} from './user-store';
import {getPersistedAuth, getPersistedUser} from './persistence';

export function initAuthenticationStore() {
  return new AuthenticationStore(getPersistedAuth());
}

export function initStores(authStore: AuthenticationStore, repos: Repositories) {
  const userStoreDeps = {
    authenticationStore: authStore,
    userRepository: repos.users,
  };

  return {
    authStore,
    userStore: new UserStore(getPersistedUser(), userStoreDeps),
  };
}

export type Stores = ReturnType<typeof initStores>;

const StoresContext = createContext<Stores>(null as never);

export const StoresProvider = StoresContext.Provider;

export function useStores() {
  return useContext(StoresContext);
}
