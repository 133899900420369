import {createContext, useContext} from 'react';
import {AuthorizationGetter} from '../models';
import {UserRepository} from './user-repository';

export function initRepositories(authorization: AuthorizationGetter) {
  const baseConfiguration = {
    apiConfig: {
      hosts: {
        lab: '',
      },
    },
    authorization,
  };

  return {
    users: new UserRepository(baseConfiguration),
  };
}

export type Repositories = ReturnType<typeof initRepositories>;

const RepositoriesContext = createContext<Repositories>(null as never);

export const RepositoriesProvider = RepositoriesContext.Provider;

export function useRepos() {
  return useContext(RepositoriesContext);
}
