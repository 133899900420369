import React from 'react';

const SectionTwo = () => {
  return (
    <div>
      <span>This is the content of Section 2</span>
    </div>
  );
};

export default SectionTwo;
