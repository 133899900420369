export interface AuthorizationDto {
  accessToken: string;
  expirationDate: Date | null;
  userId: string;
}

export interface Credentials {
  username: string;
  password: string;
}

export abstract class AuthorizationGetter {
  public abstract getAuthorization(): AuthorizationDto;

  public getCredentials(): Promise<Credentials | null> {
    return Promise.resolve(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onRenewAuthorization(renewedAuth: AuthorizationDto): void {
    // empty method
  }
}
