import {
  Divider,
  Drawer,
  IconButton,
  List,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItem,
  makeStyles,
  withStyles,
  MenuProps,
  CssBaseline,
} from '@material-ui/core';
import {AccountCircle, ExitToApp as SignOutIcon} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import {Route, Switch, Link} from 'react-router-dom';
import clsx from 'clsx';
import React, {useState} from 'react';
import logo from '../../assets/lab_logo.png';
import {EnvironmentBadge, PageContainer, PageHeader} from '../../components';
import SideMenu from './SideMenu';
import SectionOne from '../section-one/SectionOne';
import SectionTwo from '../section-two/SectionTwo';
import {useStores} from '../../stores';

const drawerWidth = 240;

const StyledMenu = withStyles(() => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))((props: MenuProps) => <Menu {...props} />);

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerRootClose: {
    width: 0,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  logo: {
    width: '100%',
  },
}));

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const classes = useStyles();
  const {userStore} = useStores();

  const handleProfileMenuOpen = (e: {currentTarget: HTMLButtonElement}) => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PageHeader drawerOpen={drawerOpen}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawerOpen(true)}
          className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}>
          <MenuIcon />
        </IconButton>
        <Typography role="heading" component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          <Switch>
            <Route exact path="/section-1">
              Section 1
            </Route>
            <Route exact path="/section-2">
              Section 2
            </Route>
          </Switch>
        </Typography>
        <div className={classes.grow} />
        <Tooltip title="Account" aria-label="account">
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            <AccountCircle />
          </IconButton>
        </Tooltip>
      </PageHeader>
      <StyledMenu
        autoFocus={false}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id="account-menu"
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}>
        <List>
          <ListItem autoFocus={false}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={userStore.user?.displayName} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              userStore.logout();
            }}>
            <ListItemIcon>
              <SignOutIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </MenuItem>
        </List>
      </StyledMenu>
      <Drawer
        variant="persistent"
        classes={{
          root: !drawerOpen ? classes.drawerRootClose : undefined,
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        open={drawerOpen}>
        <div className={classes.toolbarIcon}>
          <Link to="/">
            <img src={logo} className={classes.logo} alt="XpertSea" />
          </Link>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <SideMenu />
      </Drawer>
      <PageContainer>
        <Switch>
          <Route exact path="/section-1">
            <SectionOne />
          </Route>
          <Route path="/section-2">
            <SectionTwo />
          </Route>
          <Route path="/">
            <span>Loggued as: {userStore.user?.displayName}</span>
            <EnvironmentBadge />
          </Route>
        </Switch>
      </PageContainer>
    </div>
  );
};

export default Dashboard;
