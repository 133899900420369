export function initSegment(segmentWriteKey: string) {
  getSegment().load(segmentWriteKey);
}

export function page() {
  getSegment().page();
}

export async function identify(user: any) {
  await getSegment().identify(user.id || '', {
    name: user.name || '',
    displayName: user.displayName || '',
    email: user.email || '',
    language: user.language || '',
    timezone: user.timezone || '',
    isXpertsea: user.isXpertsea || '',
  });
}

export async function reset() {
  await getSegment().reset();
}

export async function track(
  event: string,
  /* user: UserModel | null,
  organization: OrganizationModel | null,
  customer: CustomerModel | null,
  additionalProperties?: JsonMap | undefined, */
): Promise<void> {
  const properties = {}; /* user
    ? {...Analytics.getUserInfo(user, organization, customer), ...additionalProperties}
    : {...additionalProperties}; */
  return getSegment().track(event, properties);
}

function getSegment() {
  return window.analytics;
}

/* function getUserInfo(user: UserModel, organization: OrganizationModel | null, customer: CustomerModel | null) {
  const roleDto = getRole(user, {customer: customer || undefined, organization: organization?.toDto() || undefined});

  return {
    userRole: roleDto?.role || '',
    organizationName: organization?.name || '',
    customerName: organization?.customerName || customer?.name || '',
  };
} */
