import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#51bceb',
      contrastText: '#fff',
    },
    common: {
      black: 'rgb(33,33,33)',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

export default theme;
