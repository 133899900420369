import {Redirect, Route, Switch} from 'react-router-dom';
import React from 'react';
import {observer} from 'mobx-react-lite';
import Login from './login';
import Dashboard from './dashboard';
import {useRouteListener} from '../shared/analytics/use-route-listener';
import {useStores} from '../stores';

const Root = observer(() => {
  const {userStore, authStore} = useStores();
  useRouteListener();

  return (
    <Switch>
      <Route path="/login" exact>
        {userStore.loggedIn && <Redirect to="/" />}
        <Login />
      </Route>

      <Route path="/">
        {(!userStore.loggedIn || !authStore.authorization) && <Redirect to="/login" />}
        <Switch>
          <Route default>
            <Dashboard />
          </Route>
        </Switch>
      </Route>
    </Switch>
  );
});

export default Root;
